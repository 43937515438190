import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import GalleryPart from './GalleryPart';


import bannerbg from '../../assets/images/banner.jpg';

const GalleryThree = () => {

    return (
        <React.Fragment>
            
            <SiteBreadcrumb
                pageTitle="Gallery"
                pageName="Gallery"
                breadcrumbsImg={bannerbg}
            />

            <GalleryPart />
        </React.Fragment>
    );
}

export default GalleryThree
import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import SearchModal from '../../components/Layout/Header/SearchModal';

//  Image
import Img1 from '../../assets/images/hostel.jpg'
import {Link} from "react-router-dom";

const Accommodation = () => {

    return (
        <React.Fragment>

            {/* Blog-Part-Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <div className="recent-posts mb-50">
                                    <h3 className="widget-title">QUICK LINKS</h3>
                                    <ul>
                                        <li><Link to="/fees">Fees</Link></li>
                                        <li><Link to="/course-finder">Course Finder</Link></li>
                                        <li><Link to="/news">News</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 pr-50 md-pr-14">
                            <div className='row'>
                                <div className='col-lg-12 mb-70 md-mb-50'>
                                    <div className={'blog-item'}>
                                        <div className="blog-img">
                                            <Link to={`/news-details/`}>
                                                <img
                                                    src={Img1}
                                                    alt={"Fees"}
                                                    style={{ minWidth: "100%", maxWidth: "60%", minHeight: "350px", maxHeight: "350px" }}
                                                />
                                            </Link>
                                        </div>
                                        <div className="blog-content">
                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    Accommodation
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                Students can either use accommodation provided by the University, live at home, or arrange their own accommodation. In terms of proximity and ease of access to university resources, the campus accommodation is the preferable option due to the location of the university.                                            </div>
                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    Campus Accommodation and Cost
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                Providing a space that serves as a home away from home is our priority at  Lux Mundi University. Our campus accommodation options are designed with the utmost consideration for the students’ welfare. Students will be housed in either one-person, or two-person per rooms hostel accommodation. The cost will depend on the number of students sharing, and the nature of the facilities. Private accommodation (one person per room) costs N715,412.50* per semester. Shared accommodation (two persons per room) costs N357,706.25* per semester.                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog-Part-End */}


            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />

        </React.Fragment>
    );
}

export default Accommodation;
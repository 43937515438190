
import React from 'react';
import { Helmet } from 'react-helmet';
import BlogMain from './BlogMain';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SearchModal from '../../components/Layout/Header/SearchModal';

import bannerbg from '../../assets/images/banner.jpg';
import { connect } from 'react-redux';
import { useState } from 'react';
import { descDynamicSort, dynamicSort } from '../../resources/constants';

const Blog = (props) => {
    const [newsList, setNewsList] = useState(props.NewsList.length > 0 ? props.NewsList.sort(dynamicSort("InsertedDate")) : []);
    
    
    return (
        <React.Fragment>

            <SiteBreadcrumb
                pageTitle="News"
                pageName="News"
                breadcrumbsImg={bannerbg}
            />

            <BlogMain newsList={newsList} />

            <SearchModal />
        </React.Fragment>
    );
}


const mapStateToProps = (state) => {
    return {
        NewsList: state.NewsDetails,
    };
};

export default connect(mapStateToProps, null)(Blog);


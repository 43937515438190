import React from 'react';
import OffWrap from '../../../components/Layout/Header/OffWrap';
import CourseDetailsMain from './CourseDetailsMain';


const CourseSingle = () => {

    return (
        <React.Fragment>
            <OffWrap />
            {/* Course Details Main */}
            <CourseDetailsMain />
            {/* Course Details Main */}

        </React.Fragment>
    );
}

export default CourseSingle;
import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SinglePostTwo from '../../components/Blog/SinglePostTwo';
import { Alert } from '@mui/material';
import { dynamicDateSort, formatDateAndTime, serverLink, shortCode } from '../../resources/constants';


const Blog = (props) => {

    const NewsMapper = (data) => {
        return data.sort(dynamicDateSort("InsertedDate")).map((item, index) => {
            return (
                <div className='mb-10' key={index}>
                    <SinglePostTwo
                        blogClass='row no-gutter white-bg blog-item'
                        blogImage={`${serverLink}public/uploads/${shortCode}/news_uploads/${item.ImagePath}`}
                        ImgOrder='last'
                        blogTitle={item.Title}
                        blogPublishedDate={formatDateAndTime(item.InsertedDate, "date")}
                        blogAuthor={item.FacultyCode}
                        slug={item.Slug}
                    />
                </div>
            )
        })
    }

    const EventsMapper = (data) => {
        return data.sort(dynamicDateSort("EventDate")).map((item, index) => {
            return (
                <div className="events-short mb-30" key={index}>
                    <div className="date-part bgc1">
                        <span className="month">{formatDateAndTime(item.EventDate, "short_month")}</span>
                        <div className="date">{new Date(item.EventDate).getDate()}</div>
                    </div>
                    <div className="content-part">
                        <div className="categorie">
                            <ul className="blog-meta">
                                <li className="admin"><i className="fa fa-user-o"></i> {item.FacultyCode}</li>
                            </ul>
                        </div>
                        <h4 className="title mb-0"><Link to={`/event-details/${item.Slug}`}>{item.Title}</Link></h4>
                    </div>
                </div>
            )
        })
    }

    return (
        <React.Fragment>
            <div id="rs-blog" className="rs-blog style1 modify1 pt-100 pb-106 md-pt-70 md-pb-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title mb-50 md-mb-30 text-center"
                        subtitleClass="sub-title orange"
                        titleClass="title mb-0"
                        title="Latest News & Events"
                    />
                    <div className="row">
                        <div className="col-lg-7 pr-60 md-pr-14 md-mb-30">
                            {
                                props.FacultyNewsList.length > 0 &&
                                NewsMapper(props.FacultyNewsList.slice(0, 2))
                            }
                            {
                                props.FacultyNewsList.length === 1 && props.NewsList > 0 ?
                                    NewsMapper(props.NewsList.slice(0, 1))
                                    :
                                    props.FacultyNewsList.length === 0 && props.NewsList.length > 0 ?
                                        NewsMapper(props.NewsList.slice(0, 2))
                                        :
                                        <Alert severity='warning' >No News Data</Alert>
                            }
                        </div>
                        <div className="col-lg-5 lg-pl-0 md-ml-14">

                            {
                                props.FacultyEventList.length > 0 &&
                                EventsMapper(props.FacultyEventList.slice(0, 2))
                            }
                            {
                                props.FacultyEventList.length === 1 && props.EventList > 0 ?
                                    EventsMapper(props.EventList.slice(0, 1))
                                    :
                                    props.FacultyEventList.length === 0 && props.EventList.length > 0 ?
                                        EventsMapper(props.EventList.slice(0, 2))
                                        :
                                        <Alert severity='warning' >No Events Data</Alert>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default Blog;
import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, secondParentMenu } = props;

    const location = useLocation();



    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'menu-item current-menu-item' : 'menu-item'}>
                <Link to="/">Home</Link>
            </li>
            <li className={parentMenu === 'about' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">About</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/about-us" className={location.pathname === "/about" ? "active-menu" : ""}>About Us</Link>
                    </li>
                    <li>
                        <Link to="/our-campus" className={location.pathname === "/about-2" ? "active-menu" : ""}>Our Campus</Link>
                    </li>
                    <li>
                        <Link to="/board-of-trustee" className={location.pathname === "/about-2" ? "active-menu" : ""}>Board of Trustee</Link>
                    </li>
                    <li>
                        <Link to="/management" className={location.pathname === "/about-2" ? "active-menu" : ""}>Management</Link>
                    </li>
                    <li>
                        <Link to="/senate" className={location.pathname === "/about-2" ? "active-menu" : ""}>Senate</Link>
                    </li>
                </ul>
            </li>
            <li className={parentMenu === 'course' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#" as="#">Admission</Link>
                <ul className="sub-menu">
                    <li className={parentMenu === 'pages' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                        <Link to="#">Faculties</Link>
                        <ul className="sub-menu">
                            {
                                props.FacultyListDetails.length > 0 &&
                                props.FacultyListDetails.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <Link to={`/faculties/${item.Slug}`} className={secondParentMenu === 'team' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>
                                                {item.FacultyName}</Link>

                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </li>
                    <li>
                        <Link to="/course-finder" className={location.pathname === "/course" ? "active-menu" : ""}>Course Finder</Link>
                    </li>
                    <li>
                        <Link to="/fees" className={location.pathname === "/course-2" ? "active-menu" : ""}>Fees</Link>
                    </li>
                    <li>
                        <a href='https://admission.luxmundi.smartsourcing.ng/' target='_blank' className={location.pathname === "/course-3" ? "active-menu" : ""}>Apply Now</a>
                    </li>
                </ul>
            </li>

            <li className={parentMenu === 'blog' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">Academics</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/our-staff" className={location.pathname === "/our-staff" ? "active-menu" : ""}>Staff</Link>
                    </li>
                    <li>
                        <Link to="/learning-goals" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Learning Goals</Link>
                    </li>
                    <li>
                        <Link to="/academic-registry" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Academic Registry</Link>
                    </li>
                    <li>
                        <Link to="/academic-calender" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Academic Calender</Link>
                    </li>
                    <li>
                        <Link to="/education" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Education</Link>
                    </li>

                </ul>
            </li>

            <li className={parentMenu === 'blog' ? 'menu-item-has-children current-menu-item' : 'menu-item-has-children'}>
                <Link to="#">Resources</Link>
                <ul className="sub-menu">
                    <li>
                        <Link to="/news" className={location.pathname === "/blog" ? "active-menu" : ""}>News</Link>
                    </li>
                    <li>
                        <Link to="/events" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Events</Link>
                    </li>
                    <li>
                        <Link to="/gallery" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Gallery</Link>
                    </li>
                    {/* <li>
                        <Link to="/careers" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Careers</Link>
                    </li> */}

                </ul>
            </li>


            <li className={parentMenu === 'contact' ? 'menu-item current-menu-item' : 'menu-item'}>
                <Link to="/contact-us">
                    Contact
                </Link>
            </li>
        </React.Fragment>
    );
}


const mapStateToProps = (state) => {
    return {
        FacultyListDetails: state.FacultyListDetails
    }
}
export default connect(mapStateToProps, null)(MenuItems);
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MenuItems from './MenuItems';
import RSMobileMenu from './RSMobileMenu';
import TopHeader from './TopBar';

import darkLogo from '../../../assets/img/logo/dark-logo.png';
import SideModal from '../../Common/SideModal';
import { googleMapAdress, projectEmail, projectPhone } from '../../../resources/constants';


const HeaderStyleTwo = (props) => {
	const { headerClass, headerFullWidth, parentMenu, secondParentMenu, activeMenu, headerNormalLogo, headerStickyLogo, mobileNormalLogo, mobileStickyLogo, TopBar, TopBarClass, emailAddress, phoneNumber, Location, CanvasLogo, CanvasClass } = props;
	const [menuOpen, setMenuOpen] = useState(false)

	const [isVisible, setIsVisible] = useState(false);
	useEffect(() => {
		// Sticky is displayed after scrolling for 100 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 100) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	const searchModalAdd = () => {
		document.body.classList.add('modal-open');
	};

	const canvasMenuAdd = () => {
		document.body.classList.add('nav-expanded');
	};

	// categories btn
	const catBtn = () => {
		document.body.classList.toggle('catBtnEnable');
	};

	return (
		<React.Fragment>
			<div className={headerClass ? headerClass : 'full-width-header home8-style4 main-home'}>
				<header id="rs-header" className='rs-header'>
					{
						TopBar ? <TopHeader topBarClass={TopBarClass} emailAddress={emailAddress} phoneNumber={phoneNumber} Location={Location} /> : ""
					}

					<div className={isVisible ? 'menu-area menu-sticky sticky' : 'menu-area menu-sticky'}>
						<div className={headerFullWidth ? 'container-fluid' : 'container'}>
							<div className="row y-middle">
								<div className="col-lg-4 hidden-md">
									<div className="logo-cat-wrap">
										<div className="logo-part ">
											<Link to="/">
												<img className="normal-logo" style={{ width: "50px", height: "50px" }} src={headerNormalLogo} alt="" />
												<span className="normal-logo" style={{ fontSize: "25px", color: "darkblue" }}> Lux Mundi</span>
												<img className="sticky-logo" style={{ width: "50px", height: "50px" }} src={headerNormalLogo} alt="" />
											</Link>
										</div>
									</div>
								</div>
								<div className="col-lg-8 text-center">
									<div className="rs-menu-area">
										<div className="main-menu pr-70">
											<div className="mobile-menu md-display-block">
												<Link to="/" className="mobile-normal-logo">
													<img className="normal-logo" src={headerNormalLogo} alt="" />
												</Link>
												<Link to="/" className="mobile-sticky-logo">
													<img src={headerNormalLogo} alt="logo" />
												</Link>
												<a href="#" className="rs-menu-toggle" onClick={() => {
													setMenuOpen(!menuOpen)
												}}>
													<i className="fa fa-bars"></i>
												</a>

											</div>
											<nav className="rs-menu hidden-md">
												<ul className="nav-menu">
													<MenuItems
														parentMenu={parentMenu}
														secondParentMenu={secondParentMenu}
														activeMenu={activeMenu}
													/>
												</ul>
											</nav>
										</div>
										<div className="expand-btn-inner relative">
											<ul className="expand-items">
												<li className="sidebarmenu-search">
													<a onClick={searchModalAdd} className="rs-search" href="#">
														<i className="flaticon-search"></i>
													</a>
												</li>

												<li>
													<a onClick={canvasMenuAdd} id="nav-expander" className="nav-expander" href="#">
														<span className="dot1"></span>
														<span className="dot2"></span>
														<span className="dot3"></span>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<RSMobileMenu
						menuOpen={menuOpen}
						setMenuOpen={setMenuOpen}
						parentMenu={parentMenu}
						secondParentMenu={secondParentMenu}
						headerFullWidth={headerFullWidth}
						onClick={canvasMenuAdd}
					/>
					<div onClick={() => setMenuOpen(false)} className={menuOpen ? "body-overlay show" : "body-overlay"}></div>
				</header>


				<SideModal canvasClass={CanvasClass ? CanvasClass : "right_menu_togle orange_color hidden-md"}
					canvasLogo={CanvasLogo ? CanvasLogo : darkLogo} >
					<div className="offcanvas-text">
						<p>Welcome to Lux Mundi University, a private institution located in Umuahia, Abia State, Nigeria. At Lux Mundi University, we are committed to providing a world-class education that opens doors to a bright future for our students. With a focus on high-quality integrated education, we aim to become one of the leading universities globally, promoting excellence, industry, and transforming lives on a global scale.</p>
					</div>
					{/* <div className="offcanvas-gallery">
						<Gallery
							photos={photos}
							onClick={openLightbox}

						/>
					</div> */}

					<div className="col-lg-12 col-md-12 col-sm-12 footer-widget">
						<ul className="address-widget address2">
							<li className='mb-20'>
								<i className="flaticon-location"></i>
								<div className="desc">
									New Ndagbo City, <br />
									Isiama Afaraukwu-Ibeku,
									Umuahia, Abia State, Nigeria.
								</div>
							</li>
							<li className='mb-20'>
								<i className="flaticon-call"></i>
								<div className="desc">
									<a href={`tel:${projectPhone}`}>{projectPhone}</a>
								</div>
							</li>
							<li>
								<i className="flaticon-email"></i>
								<div className="desc">
									<a href={`mailto:${projectEmail}`}>{projectEmail}</a>
								</div>
							</li>
						</ul>
					</div>

					<div className="map-img">
						<iframe
							title="Lux Mundi University Campus Map"
							src={googleMapAdress}
							width="100%" height={"200px"} style={{ border: "0" }} allowFullScreen="" loading="lazy"
							referrerPolicy="no-referrer-when-downgrade" />;
					</div>
					<div className="canvas-contact">
						<ul className="social">
							<li>
								<a href="#"><i className="fa fa-facebook-f"></i></a>
							</li>
							<li>
								<a href="#"><i className="fa fa-twitter"></i></a>
							</li>
							<li>
								<a href="#"><i className="fa fa-pinterest"></i></a>
							</li>
							<li>
								<a href="#"><i className="fa fa-instagram"></i></a>
							</li>
						</ul>
					</div>
				</SideModal>
			</div>
		</React.Fragment>
	);
}

export default HeaderStyleTwo;
import React, {useEffect, useState} from 'react';
import SingleTeam from '../../components/Team/SingleTeam'
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import bannerbg from '../../assets/images/banner.jpg';
import teamImg1 from '../../assets/img/team/1.jpg';
import axios from "axios";
import {connect} from "react-redux";
import {ImageUrlFormatter, serverLink} from "../../resources/constants";
import {setHRStaffDataDetails, setHRStaffListDetails} from "../../actions/setactiondetails";
import {Link} from "react-router-dom";

const StaffList = (props) => {
    const [loading, setLoading] = useState(true);
    const [searching, setIsSearching] = useState("");
    const getHRStaffList = async () => {
        await axios
            .get(`${serverLink}academic/staff/all/`)
            .then((response) => {
                props.setOnHRStaffListDetails(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    const getHRStaffData = async () => {
        await axios
            .get(`${serverLink}academic/staff/data/`)
            .then((response) => {
                props.setOnHRStaffDataDetails(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    const updateStaffHitCount = async (id) => {
        await axios
            .put(`${serverLink}academic/update/staff/hit/count/${id}`)
            .then((response) => {})
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    useEffect(() => {
        getHRStaffList().then((r) => {});
        getHRStaffData().then((r) => {});
    }, []);
    return (
        <React.Fragment>
            <OffWrap />

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Staff List"
                pageName="Staff List "
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            <div className="rs-team style1 orange-style pt-100 pb-50 md-pt-80 md-pb-30 white-bg">
                <div className="container">
                    <div className="widget-area">
                        <div className="search-widget mb-50">
                            <h3 className="widget-title">Staff Search</h3>
                            <div className="search-wrap">
                                <input type="search"
                                       placeholder="Find Staff"
                                       onChange={(event) => setIsSearching(event.target.value)}
                                       className="search-input" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            props.hrStaffList.filter((items) => {
                                if (searching === "") {
                                    return items;
                                }else  if (
                                    items.FirstName.toLowerCase().includes(searching.toLowerCase()) ||
                                    items.MiddleName.toLowerCase().includes(searching.toLowerCase()) ||
                                    items.Surname.toLowerCase().includes(searching.toLowerCase())
                                ){
                                    return items;
                                }
                            }).map((result, index) => (
                                <div className="col-lg-3 col-md-4 mb-50" key={index}>
                                    <Link
                                        to={`/staff-details/${result.Slug}`.toLowerCase()}
                                        onClick={() => updateStaffHitCount(result.EntryID)}
                                    >
                                    <SingleTeam
                                        itemClass="team-item"
                                        Image={ImageUrlFormatter(result.Image, "academic/staff" )}
                                        Title={result.FirstName + " " + result.MiddleName + " " + result.Surname}
                                        Designation={result.DesignationName}
                                        item={result}
                                    />
                                    </Link>
                                </div>
                                ))
                        }
                    </div>
                </div>
            </div>
            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        hrStaffList: state.HRStaffListDetails,
        hrStaffData: state.HRStaffDataDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHRStaffListDetails: (p) => {
            dispatch(setHRStaffListDetails(p));
        },
        setOnHRStaffDataDetails: (p) => {
            dispatch(setHRStaffDataDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import OverviewPart from './OverviewPart';
import InstructorPart from './InstructorPart';
import CurriculumPart from './CurriculumPart';
import {serverLink, shortCode} from "../../../resources/constants";


const CourseDetailsTab = (props) => {


    let tab1 = "Overview",
        tab2 = "Curriculum",
        tab3 = "Staff"

    const tabStyle = 'intro-tabs tabs-box w-100';

    return (
        <div className="intro-info-tabs">
            <div className="img img-fluid row">
                <img
                    src={props.courseDetail.length > 0 ? props.courseDetail[0]?.ImagePath !== null ? props.courseDetail[0]?.ImagePath.includes("simplefileupload") ? props.courseDetail[0]?.ImagePath : `${serverLink}public/uploads/${shortCode}/course_uploads/${props.courseDetail[0]?.ImagePath}` : "" : ""}
                    alt={""}
                    style={{width: '100%'}}
                />
            </div>
            <h2>{props.courseList[0]?.CourseName}</h2>
            <Tabs>
                <TabList className={tabStyle}>
                    <Tab style={{width: '33.3%'}}>
                        <button>{tab1}</button>
                    </Tab>
                    <Tab style={{width: '33.3%'}}>
                        <button>{tab2}</button>
                    </Tab>
                    <Tab style={{width: '33.3%'}}>
                        <button>{tab3}</button>
                    </Tab>
                </TabList>

                <TabPanel>
                    <OverviewPart Description={props.courseDetail[0]?.Description} />
                </TabPanel>

                <TabPanel>
                    <CurriculumPart />
                </TabPanel>

                <TabPanel>
                    <InstructorPart />
                </TabPanel>

            </Tabs>
            {/* <ul className="nav nav-tabs intro-tabs tabs-box" id="myTab" role="tablist">
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn active" id="prod-overview-tab" data-toggle="tab" href="#prod-overview" role="tab" aria-controls="prod-overview" aria-selected="true">Overview</a>
                </li>
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn" id="prod-curriculum-tab" data-toggle="tab" href="#prod-curriculum" role="tab" aria-controls="prod-curriculum" aria-selected="false">Curriculum</a>
                </li>
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn" id="prod-instructor-tab" data-toggle="tab" href="#prod-instructor" role="tab" aria-controls="prod-instructor" aria-selected="false">Instructor</a>
                </li>
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn" id="prod-faq-tab" data-toggle="tab" href="#prod-faq" role="tab" aria-controls="prod-faq" aria-selected="false">Faq</a>
                </li>
                <li className="nav-item tab-btns">
                    <a className="nav-link tab-btn" id="prod-reviews-tab" data-toggle="tab" href="#prod-reviews" role="tab" aria-controls="prod-reviews" aria-selected="false">Reviews</a>
                </li>
            </ul>
            <div className="tab-content tabs-content" id="myTabContent">
                <div className="tab-pane tab fade active show" id="prod-overview" role="tabpanel" aria-labelledby="prod-overview-tab">
                    <OverviewPart />
                </div>
                <div className="tab-pane fade" id="prod-curriculum" role="tabpanel" aria-labelledby="prod-curriculum-tab">
                    <CurriculumPart />
                </div>
                <div className="tab-pane fade" id="prod-instructor" role="tabpanel" aria-labelledby="prod-instructor-tab">
                    <InstructorPart />
                </div>
                <div className="tab-pane fade" id="prod-faq" role="tabpanel" aria-labelledby="prod-faq-tab">
                    <FaqPart />
                </div>
                <div className="tab-pane fade" id="prod-reviews" role="tabpanel" aria-labelledby="prod-reviews-tab">
                    <ReviewPart />
                </div>
            </div> */}
        </div>
    );
}


export default CourseDetailsTab;
import React from 'react';
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import bannerbg from "../../assets/images/banner.jpg";
import PagesSidebar from "../pages-sidebar/pages-sidebar";
import {connect} from "react-redux";

const Education = (props) => {
    return (
        <React.Fragment>
            <OffWrap />
            <SiteBreadcrumb pageTitle="Education"
                            pageName="Education"
                            breadcrumbsImg={bannerbg} />
            <div className="rs-about style1 pt-82 md-pt-50 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 pr-70 md-pr-14 mb-20">
                            <div className="about-content">
                                <div
                                    dangerouslySetInnerHTML={{ __html: props.homeData.EducationContent }} style={{textAlign: "justify"}}
                                />
                            </div>
                        </div>
                        <PagesSidebar/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};
export default connect(mapStateToProps, null)(Education);
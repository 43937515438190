import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import SectionTitle from '../../components/Common/SectionTitle';

// About Image
import countIcon1 from '../../assets/img/about/style3/icons/1.png';
import countIcon2 from '../../assets/img/about/style3/icons/2.png';
import countIcon3 from '../../assets/img/about/style3/icons/3.png';
import FacultySideBar from './faculty-sidebar';

const AboutCounter = (props) => {
    // console.log(props)
    const [state, setState] = useState(true);

    const counters = [
        {
            countNum: 2,
            countTitle: 'Students',
            counterPrefix: 'k+',
            countIcon: countIcon1
        },
        {
            countNum: 50,
            countTitle: 'Average CGPA',
            counterPrefix: '',
            countIcon: countIcon2
        },
        {
            countNum: 95,
            countTitle: 'Graduates',
            counterPrefix: '%',
            countIcon: countIcon3
        }

    ];

    return (
        <div id="rs-about" className="rs-about style3 pt-110 md-pt-70">
            {
                props.facultyData.length > 0 &&
                <div className="container">
                    <div className="row y-top">
                        <div className="col-lg-7 lg-pr-0 md-mb-30">
                            <div className="about-intro md-pr-16">
                                <SectionTitle
                                    sectionClass="sec-title"
                                    subtitleClass="sub-title orange"
                                    titleClass="title mb-20"
                                    title={props.faculty[0].FacultyName}
                                    descClass="desc big"
                                    description={<p dangerouslySetInnerHTML={{ __html: props.facultyData[0].FacultyDetail }} />}
                                />
                            </div>
                        </div>
                        <div className="col-lg-5 pl-82 md-pl-14">
                            <FacultySideBar courseList={props.courseList} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default AboutCounter;
import React from 'react';
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import bannerbg from "../../assets/images/banner.jpg";

const AcademicCalendar = () => {
    return (
        <React.Fragment>
            <OffWrap />

            <SiteBreadcrumb pageTitle={`${new Date().getFullYear()} Academic Calendar`}
                            pageName="Academic Calendar"
                            breadcrumbsImg={bannerbg} />
            <div className="rs-about style1 pt-82 md-pt-50 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 pr-70 md-pr-14 mb-20">
                            <div className="about-content">
                                {/* Section Title Start */}
                                <div className='sec-title mb-44 md-mb-0'>
                                    <h3 style={{color: "#273c66"}}><strong>Academic Calendar</strong></h3>
                                    <div className="col-12">
                                        <p className="mb-4" style={{textAlign: "justify"}}>Lux Mundi University academic calendar is divided into two semesters. Students typically register minimum of 16 credit units and maximum of 26 credit units per semester. A credit unit typically includes lectures, interactive sessions, laboratory/workshops, seminars and field trips</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 padding-0 md-pl-14 md-pr-14 md-mb-30">
                            <div className="widget-area">
                                <div className="recent-posts mb-50">
                                    <h3 className="widget-title">Please Note:</h3>
                                    <strong><hr/></strong>
                                    <p>All dates are subject to change</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AcademicCalendar;
import React, { useEffect, useState } from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import bannerbg from "../../assets/images/banner.jpg";
import axios from "axios";
import { serverLink, shortCode } from "../../resources/constants";
import SideModal from "../../components/Common/SideModal";

const Management = (props) => {
    const { CanvasClass } = props
    const [managementList, setManagementList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getManagementList() {
            const result = await axios.get(`${serverLink}leadership/management/list`);
            setManagementList(result.data);
            setLoading(false);
        }
        getManagementList();
    });

    const canvasMenuAdd = (management) => {
        setDet({
            ...det_,
            Image: `${serverLink}public/uploads/${shortCode}/leaderships_uploads/${management.ImagePath}`,
            Description: <p dangerouslySetInnerHTML={{ __html: management.Description }} />

        })
        document.body.classList.add('nav-expanded');
    };

    const [det_, setDet] = useState({
        Image: "",
        Description: ""
    })

    return (
        <React.Fragment>
            <OffWrap />

            <SiteBreadcrumb pageTitle="Management"
                            pageName="Management"
                            breadcrumbsImg={bannerbg} />

            <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">
                <div className="container">
                    <div className="col-12">
                        <h3 className="text-center" style={{color: "#273c66"}}><strong>Management</strong></h3>
                        <div className="d-flex align-items-center align-content-between flex-column">
                            <div className="row">
                                {managementList?.map((management, index) => (
                                    <div
                                        className={`text-center ${index === 0 ? "col-md-12 d-block" : "col-md-4"
                                        }  `}
                                        key={index}
                                    >
                                        <img
                                            style={{ height: 350, width: 400 }}
                                            src={`${serverLink}public/uploads/${shortCode}/leaderships_uploads/${management.ImagePath}`}
                                            alt={management.FullName} className="img-thumbnail"
                                        />
                                        <h4 className="my-2" style={{color: "#273c66"}}>
                                            <strong>{management.Title} {management.FullName}</strong>
                                        </h4>
                                        <h5 className="mb-2" style={{color: "#273c66"}}><strong>{management.Designation}</strong></h5>
                                        <p style={{ cursor: 'pointer' }} className="text-center mb-5"
                                           onClick={() => { canvasMenuAdd(management) }}
                                        >View profile</p>
                                    </div>
                                ))}
                            </div>
                            <SideModal
                                canvasClass={CanvasClass ? CanvasClass : "right_menu_togle orange_color hidden-md"}
                                canvasLogo={det_?.Image}
                            >
                                <div style={{textAlign : "justify"}}>
                                    {det_?.Description}
                                </div>
                            </SideModal>
                        </div>
                    </div>
                </div>
            </div>
            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

        </React.Fragment>
    )
}

export default Management;
import React, { useEffect, useState } from 'react';
import Team from './TeamSection';
import Blog from './BlogSection';
import AboutVideo from './VideoSection';
import AboutCounter from './CounterSection';
import Testimonial from './TestimonialSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';


const FacultyMain = (props) => {
    const navigate = useLocation();

    const { slug } = useParams();

    const [faculty, setFaculty] = useState(
        props.FacultyListDetails.length > 0 ?
            props.FacultyListDetails.filter(x => x.Slug === slug) : []
    )
    const [facultyData, setFacultyData] = useState(
        props.FacultyDataDetails.length > 0 ?
            props.FacultyDataDetails.filter(x => x.FacultyCode === faculty[0]?.FacultyCode) : []
    )

    const [courseList, setCourseList] = useState(props.CourseListDetails.length > 0 ?
        props.CourseListDetails.filter(x => x.FacultyCode === faculty[0]?.FacultyCode) : [])


    const [eventList, setEventList] = useState(props.EventList.length > 0 ?
        props.EventList.filter(x => x.FacultyCode === faculty[0]?.FacultyCode) : [])

    const [newsList, setNewsList] = useState(props.NewsList.length > 0 ?
        props.NewsList.filter(x => x.FacultyCode === faculty[0]?.FacultyCode) : [])


    useEffect(() => {
        setFaculty(
            props.FacultyListDetails.length > 0 ?
                props.FacultyListDetails.filter(x => x.Slug === slug) : []
        )
    }, [slug]);

    useEffect(() => {

        if (faculty.length > 0) {
            const fac_data = props.FacultyDataDetails.length > 0 ?
                props.FacultyDataDetails.filter(x => x.FacultyCode === faculty[0]?.FacultyCode) : [];
            if (fac_data.length > 0) {
                //faculty data
                setFacultyData(fac_data);
                props.setMainPageFaculty([{...fac_data[0], FacultyName: faculty[0]?.FacultyName }])
    //courses
    setCourseList(props.CourseListDetails.length > 0 ?
        props.CourseListDetails.filter(x => x.FacultyCode === faculty[0]?.FacultyCode) : []);

    //events
    setEventList(props.EventList.length > 0 ?
        props.EventList.filter(x => x.FacultyCode === faculty[0]?.FacultyCode) : []);

    //news
    setNewsList(props.NewsList.length > 0 ?
        props.NewsList.filter(x => x.FacultyCode === faculty[0]?.FacultyCode) : [])

    //feedback
    // axios.get(`${serverLink}general/website-feedback`).then((res) => {
    //     console.log(res)
    // })

} else {
    window.location.href = "/"
}
        } else {
    window.location.href = "/"
}
    }, [faculty])

return (
    <React.Fragment>
        {/* AboutCounter Start */}
        <AboutCounter faculty={faculty} facultyData={facultyData} courseList={courseList} />
        {/* AboutCounter End */}

        <Blog FacultyNewsList={newsList} NewsList={props.NewsList} EventList={props.EventList} FacultyEventList={eventList} />
        {/* Blog Section End */}

        {/* Team Section Start */}
        {/* <Team /> */}
        {/* Team Section End */}

        {/* Testimonial Section Start */}
        {/* <Testimonial /> */}
        {/* Testimonial Section End */}

        {/* Blog Section Start */}

    </React.Fragment>
)
}

const mapStateToProps = (state) => {
    return {
        EventList: state.EventDetails,
        FacultyListDetails: state.FacultyListDetails,
        NewsList: state.NewsDetails,
        CourseListDetails: state.CourseListDetails,
        FacultyDataDetails: state.FacultyDataDetails
    };
};

export default connect(mapStateToProps, null)(FacultyMain);


import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import SearchModal from '../../components/Layout/Header/SearchModal';

//  Image
import Img1 from '../../assets/images/cafteria.jpg'
import {Link} from "react-router-dom";

const Feeding = () => {

    return (
        <React.Fragment>

            {/* Blog-Part-Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <div className="recent-posts mb-50">
                                    <h3 className="widget-title">QUICK LINKS</h3>
                                    <ul>
                                        <li><Link to="/fees">Fees</Link></li>
                                        <li><Link to="/course-finder">Course Finder</Link></li>
                                        <li><Link to="/news">News</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 pr-50 md-pr-14">
                            <div className='row'>
                                <div className='col-lg-12 mb-70 md-mb-50'>
                                    <div className={'blog-item'}>
                                        <div className="blog-img">
                                            <Link to={`/news-details/`}>
                                                <img
                                                    src={Img1}
                                                    alt={"Fees"}
                                                    style={{ minWidth: "100%", maxWidth: "60%", minHeight: "350px", maxHeight: "350px" }}
                                                />
                                            </Link>
                                        </div>
                                        <div className="blog-content">
                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    Feeding
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                The meals arrangement is solely-based on the students’ preference. The school however has made a provision for students and parents that want to regulate the feeding cost in an effective manner. Students can pay a certain amount (there are no limitations) and be given a feeding booklet. Using the slips in this booklet, they can buy food from the eating areas that use this method as a form of payment. This reduces the need to carry around cash whilst ensuring that students are properly fed and satisfied. Currently, the only cafeterias that use this form of transaction is Brim and Terminal 2
                                            </div>
                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    The Brim
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                The Brim was the university’s first open cafeteria. Since its inception, the Brim has been dedicated to serving the most delightful array of dishes to ensure that their customers have an enjoyable moment at the university. Ranging from rice dishes to traditional swallows, the Brim has proven to be a connoisseur of well-made food. It also includes an outdoor arena where students can relax and enjoy their meals as well as a shawarma shack.
                                            </div>

                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    Grilled food
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                Grilled food is always a delight to partake in and WSauce provides just the right amount of beautifully grilled meals that will leave you pandering for extra helpings. Their exhaustive menu of grilled meats, shawarma, and even noodles are popular amongst the students of the university.                                            </div>
                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    The Tuck Shop
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                The Tuck Shop offers a range of delicacies including grilled awesomeness such as burgers and hotdogs served with a side of crispy fries. Other appetizing meals include wings (barbecued or crispy) as well as corndogs. The meals served in this center are carefully and deliciously made and has made the center a connoisseur of gourmet food.                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog-Part-End */}


            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />

        </React.Fragment>
    );
}

export default Feeding;
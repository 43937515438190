import React from 'react';
import { Link } from 'react-router-dom';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {connect} from "react-redux";

const AboutMain = (props) => {

    return (
        <React.Fragment>

            <div className="rs-about style1 pt-82 md-pt-50 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 pr-70 md-pr-14">
                            <div className="about-content">
                                <div className='sec-title mb-44 md-mb-0'>
                                    <h3 className="title mb-10" style={{color: "#273c66"}}><strong>About Us</strong></h3>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: props.homeData.AboutContent }} style={{textAlign: "justify"}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4  padding-0 md-pl-14 md-pr-14 md-mb-30">
                                <div className="widget-area">
                                    <div className="recent-posts mb-50">
                                        <h3 className="widget-title">Additional Information</h3>
                                        <ul>
                                            <li className="mb-20">
                                                <Link to={`/our-campus`}>
                                                    Our Campus
                                                    <i className="fa fa-angle-right float-end text-dark" />
                                                </Link>
                                            </li>
                                            <li className="mb-20">
                                                <a href='https://admission.luxmundi.smartsourcing.ng/' target='_blank'>
                                                    Courses
                                                    <i className="fa fa-angle-right float-end text-dark" />
                                                </a>
                                            </li>
                                            <li className="mb-20">
                                                <Link to={`/admission/application`}>
                                                    Application
                                                    <i className="fa fa-angle-right float-end text-dark" />
                                                </Link>
                                            </li>
                                            <li className="mb-20">
                                                <Link to={`/gallery`}>
                                                    Gallery
                                                    <i className="fa fa-angle-right float-end text-dark" />
                                                </Link>
                                            </li>
                                            <li className="mb-20">
                                                <Link to={`/news`}>
                                                    News
                                                    <i className="fa fa-angle-right float-end text-dark" />
                                                </Link>
                                            </li>
                                            <li className="mb-20">
                                                <Link to={`/event`}>
                                                    Event
                                                    <i className="fa fa-angle-right float-end text-dark" />
                                                </Link>
                                            </li>
                                        </ul>
                                        <div className="btn-part">
                                            <a className="readon2 dark-hov col-md-12 text-center" target='_blank' href='https://admission.luxmundi.smartsourcing.ng/'>Apply Now</a>
                                        </div>
                                    </div>
                                </div>
                            <Accordion defaultExpanded className="mb-20">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon className={"text-white"} />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header" style={{backgroundColor: "#273c66"}}
                                >
                                    <div className="text-white">
                                        <span ><h5 className="text-white">Our Vision</h5></span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p  style={{textAlign: "justify"}}> The vision of the proposed Lux Mundi University, Umuahia is “to be a world-class institution that offers students the golden key to a bright future through high quality integrated education”. The University aims to be among the best Universities in the world, promoting excellence and industry, touching lives globally through excellent and quality tertiary education that shall rank favorably among the best universities in the world.
                                    </p>
                                    <p style={{textAlign: "justify"}}>
                                        The university intend to make Nigeria the new global hub of deeper academic endeavors, through which the cure of her endemic challenges of tertiary education admissions would be realized
                                    </p>

                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon className={"text-white"} />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header" style={{backgroundColor: "#273c66"}}
                                >
                                    <div className="text-white">
                                        <span><h5 className="text-white">Our Mission</h5></span>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p  style={{textAlign: "justify"}}> The mission of the proposed Lux Mundi University is:
                                        “To encourage students to pursue enlightenment critically and to engage the world with creativity and professionalism”.
                                        “To produce graduates outstanding in character, sound knowledge and specialised skills”; and
                                        “To create a conducive environment that enhances research, teaching and learning”.
                                        The law establishing the proposed Lux Mundi University, Umuahia aims to achieve the following objectives to:
                                    </p>
                                    <ol type={"i"} style={{textAlign: "justify"}}>
                                        <li>To be a catalyst institution committed to revolutionizing the development and management of private University education in Nigeria with a view to progressively becoming a leading national, continental and global brand in all her courses and programmes; through the amelioration of infrastructural facilities deficit, responsible for lower admission intakes into Nigerian universities by providing same at higher quality standards, quantity and at most socially competitive fees.</li>
                                        <li>To pursue the revolution with higher intensity research into developing and sustaining of Africa’s businesses, infrastructures and improvement of public institutions management on the continent to support and boost Africa’s industrialization.</li>
                                        <li>To systematically through knowledge transfer, impact and leadership re-orientation, ensure that the over 85% of publicly funded institutions and infrastructure failures on African continent is reversed through a well-coordinated research using Nigeria as a case study.</li>
                                        <li>To academically build a conscious base through an expanded and integrated course structures and programmes that will develop and promote African Business Education and Environment (ABEE) contents; and to bridge the global knowledge gap, that Nigeria, and indeed Africa shall in two decades become a knowledge power house.</li>
                                        <li>And to structurally craft a University model that will through research and development promote and graduate first grade professionals, technologists and scientists in the low, middle and higher levels manpower base to support and boost the quest for industry, mines, agriculture and national industrialization in the Federal Republic of Nigeria</li>
                                    </ol>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        homeData: state.HomepageContentDetails,
    };
};
export default connect(mapStateToProps, null)(AboutMain);

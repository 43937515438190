import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import DegreeSingle from '../../components/Degree/DegreeSingle';

// Images
import image1 from '../../assets/images/management2.jpg'
import image2 from '../../assets/images/arts2.png'
import { connect } from 'react-redux';


const Degree = (props) => {

    return (
        <div className="rs-degree style1 modify gray-bg pt-100 pb-70 md-pt-70 md-pb-40">
            <div className="container">
                <div className="row y-middle">
                    <div className="col-lg-4 col-md-6 mb-30">
                        {/* Section Title Start */}
                        <SectionTitle
                            sectionClass="sec-title"
                            subtitleClass="sub-title primary"
                            titleClass="title mb-0"
                            title="Complete A Degree at Lux Mundi University"
                        />
                        {/* Section Title End */}
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <DegreeSingle
                            itemImage={image1}
                            descClass=""
                            title="Management and Social Science"
                            desc="Get a holistic understanding of human behavior, organizations, and the dynamics of societies. "
                            itemLink="/faculties/faculty-of-management-and-social-sciences"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <DegreeSingle
                            itemImage={image2}
                            title="Arts and Humanities"
                            desc="Drive creativity with ideas in various industries with our Computing Degrees"
                            itemLink="/faculties/faculty-of-arts-and-humanities"
                        />
                    </div>


                    <div className='row text-center mt-50'>
                        <h2 style={{ marginBottom: "30px" }}>Why Chose Us</h2>
                        <div className="col-lg-6 col-md-6 mb-10">
                            <div className="rs-latest-events style1 ">
                                <div className="event-wrap">
                                    <div className={'events-short mb-10'} data-aos={'fade-up'} >
                                        <div className="content-part">
                                            <h4 className={'title mb-0'}>
                                                Multidisciplinary study programs
                                            </h4>
                                            <div className="categorie">
                                                Highly competitive rates yet, premium academic delivery
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-10">
                            <div className="rs-latest-events style1 ">
                                <div className="event-wrap">
                                    <div className={'events-short mb-10'} data-aos={'fade-up'} >
                                        <div className="content-part">
                                            <h4 className={'title mb-0'}>
                                                International study programs
                                            </h4>
                                            <div className="categorie">
                                                The experiential learning ensures professional student success.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 mb-10">
                            <div className="rs-latest-events style1 ">
                                <div className="event-wrap">
                                    <div className={'events-short mb-10'} data-aos={'fade-up'} >
                                        <div className="content-part">
                                            <h4 className={'title mb-0'}>
                                                Highly qualified teaching staff
                                            </h4>
                                            <div className="categorie">
                                                Best learning Environment with Globally acclaimed professionals
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        EventList: state.EventDetails,
        FacultyListDetails: state.FacultyListDetails
    };
};

export default connect(mapStateToProps, null)(Degree);

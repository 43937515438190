
import { Link } from 'react-router-dom';

const SinglePostThree = (props) => {
    const { blogClass, blogImage, blogTitle, contentClass, blogDesc, btnClass, cmtQnty, blogAuthor, blogPublishedDate, blogButtonText } = props;
    return (
        <div className={blogClass ? blogClass : 'blog-item mb-44'}>
            <div className="image-part">
                <Link to={`/news-details/${props.slug}`}>
                    <img
                        src={blogImage}
                        alt={blogTitle}
                        height={"300px"}
                        style={{ maxHeight: "300px", minHeight: "300px" }}
                    />
                </Link>
            </div>
            <div className={contentClass ? contentClass : 'blog-content'}>
                <ul className="blog-meta">
                    <li className="admin"><i className="fa fa-user-o"></i> {blogAuthor ? blogAuthor : 'admin'}</li>
                    <li className="date"><i className="fa fa-calendar-check-o"></i> {blogPublishedDate ? blogPublishedDate : 'June 15, 2019'}</li>
                </ul>
                <h4 className="title">
                    <Link to={`/news-details/${props.slug}`}>
                        {blogTitle}
                    </Link>
                </h4>
                <div className="desc">{blogDesc}</div>
                <ul className="blog-bottom">
                    <li className="btn-part">
                        <Link to={`/news-details/${props.slug}`}>
                            <span className={btnClass ? btnClass : 'readon-arrow'}>{blogButtonText ? blogButtonText : 'Read More'}</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>

    )
}

export default SinglePostThree
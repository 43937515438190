import React from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import SearchModal from '../../components/Layout/Header/SearchModal';
//  Image
import Img1 from '../../assets/images/fee2.jpg'
import {Link} from "react-router-dom";

const Tuition = () => {

    return (
        <React.Fragment>

            {/* Blog-Part-Start */}
            <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 order-last">
                            <div className="widget-area">
                                <div className="recent-posts mb-50">
                                    <h3 className="widget-title">QUICK LINKS</h3>
                                    <ul>
                                        <li><Link to="/fees">Fees</Link></li>
                                        <li><Link to="/course-finder">Course Finder</Link></li>
                                        <li><Link to="/news">News</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 pr-50 md-pr-14">
                            <div className='row'>
                                <div className='col-lg-12 mb-70 md-mb-50'>
                                    <div className={'blog-item'}>
                                        <div className="blog-img">
                                            <Link to={`/news-details/`}>
                                                <img
                                                    src={Img1}
                                                    alt={"Fees"}
                                                    style={{ minWidth: "100%", maxWidth: "60%", minHeight: "350px", maxHeight: "350px" }}
                                                />
                                            </Link>
                                        </div>
                                        <div className="blog-content">
                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    Tuition Payment
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                Depending on the student's payment preference, the tuition fee can either be paid per semester or per academic year. If the student chooses the per semester payment method but is unable to provide the complete required funds, he or she can pay in installments. However, complete payment of tuition is required before the commencement of the exam period.
                                            </div>
                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    Tuition Cost
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                Currently there is no distinction between the tuition cost for Domestic students (students of Nigerian nationality) and International students.
                                            </div>


                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2} className="text-center"><h4>Undergraduate</h4></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Faculty of Computing & Apllied Sciences</td>
                                                        <td>	N2,722,500* (2 Semesters)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Faculty of Management & Social Sciences</td>
                                                        <td>	N2,722,500* (2 Semesters)</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    Tuition Revision
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                The tuition cost is subject to revisions by the Senate of the university. This tuition change can be as a result of economic situations in the country or introduction of new systems that will lead to a more efficient learning environment. All students and parents/guardians will be informed of any new development regarding the tuition cost.
                                            </div>
                                            <h3 className="blog-title">
                                                <Link to={`/news-details/`}>
                                                    Tuition and Admission
                                                </Link>
                                            </h3>
                                            <div className="blog-desc" style={{textAlign: 'justify'}}>
                                                Getting an admission at the university does not guarantee a place unless the tuition is paid. Thus, for incoming or transfer students, paying the tuition is part of the acceptance process into the university. The tuition cost only covers the cost of courses undertaken for the semester.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog-Part-End */}


            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />

        </React.Fragment>
    );
}

export default Tuition;
import React from 'react';
import { Helmet } from 'react-helmet'
import HeaderStyleTwo from '../../components/Layout/Header/HeaderStyleTwo';
import SearchModal from '../../components/Layout/Header/SearchModal';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
import HomeThreeMain from './HomeThreeMain';
import OffWrap from '../../components/Layout/Header/OffWrap';

import favIcon from '../../assets/img/fav.png';
import Logo from '../../assets/images/logo.png';
import logo from '../../assets/img/logo/logo.png';
import Loader from '../../components/Common/loader';

const HomeThree = () => {
    return (
        <React.Fragment>
            {/* <Loader/> */}
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
           
            <HomeThreeMain />
           
            {/* <SearchModal /> */}
        </React.Fragment>
    );
}


export default HomeThree;
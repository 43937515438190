import React from 'react';
import { Link } from 'react-router-dom';

const PostNavigation = ({item, type}) => {
    return (
        <div className="ps-navigation">
            <ul>
                <li><Link to={`/${type === "news" ? "news-details" : "event-details"}/${item.Slug}`}><span className="next-link">Next<i className="flaticon-next"></i></span></Link></li>
                <li><Link to={`/${type === "news" ? "news-details" : "event-details"}/${item.Slug}`}><span className="link-text">{item.Title} </span></Link></li>
            </ul>
        </div>
    )
}

export default PostNavigation;
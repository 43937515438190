import React, {useEffect, useState} from 'react';
import CourseSidebar from './CourseSidebar';
import CourseDetailsTab from './CourseDetailsTab';
import {setCourseDataDetails, setCourseListDetails} from "../../../actions/setactiondetails";
import {connect} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {serverLink} from "../../../resources/constants";
import axios from "axios";


const CourseDetailsPart = (props) => {
    const navigate = useHistory();
    const location = useLocation();
    const { slug } = useParams();

    if (slug === "") navigate("/admission/application");

    const [loading, setLoading] = useState(false);
    let courseList = props.courseList.filter((item) => item.Slug === slug);
    const [courseDetail, setCourseDetail] = useState(
        courseList.length > 0
            ? props.courseDetails.filter(
                (item) => item.CourseCode === courseList[0].CourseCode
            )
            : []
    );

    useEffect(() => {
        const slug_record =
            location.pathname.split("/")[location.pathname.split("/").length - 1];
        courseList.push(
            props.courseList.filter((item) => item.Slug === slug_record)
        );
        setCourseDetail(
            props.courseDetails.filter(
                (item) => item.CourseCode === courseList[0].CourseCode
            )
        );
    }, [location.pathname]);

    const getCourseData = async () => {
        await axios
            .get(`${serverLink}academics/course/view/list`)
            .then((response) => {
                const data = response.data;
                const course_data = data.filter((item) => item.Slug === slug);
                props.setOnCourseListDetail(response.data);
                if (data.filter((item) => item.Slug === slug).length < 1) {
                    navigate("/admission/application");
                } else {
                    courseList.push(course_data);
                }
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });

        await axios
            .get(`${serverLink}academics/course/details`)
            .then((response) => {
                props.setOnCourseData(response.data);
                setCourseDetail(
                    response.data.filter(
                        (item) => item.CourseCode === courseList[0].CourseCode
                    )
                );
                if (courseDetail.length === 0) navigate("/admission/application");
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    };

    useEffect(() => {
        getCourseData();
    }, [courseDetail.length === 0]);

    return (
        <React.Fragment>
            <div className="intro-section gray-bg pt-94 pb-100 md-pt-80 md-pb-80 loaded">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-8 md-mb-50">
                            <CourseDetailsTab courseList={courseList} courseDetail={courseDetail} />
                        </div>
                        <div className="video-column col-lg-4">
                            <CourseSidebar courseList={courseList} />
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        courseList: state.CourseListDetails,
        courseDetails: state.CourseDataDetails,
        departmentList: state.DepartmentListDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnCourseListDetail: (p) => {
            dispatch(setCourseListDetails(p));
        },
        setOnCourseData: (p) => {
            dispatch(setCourseDataDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailsPart);

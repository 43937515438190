import React, { useEffect, useState } from 'react';
import ScrollToTop from '../../components/Common/ScrollTop';
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import bannerbg from "../../assets/images/banner.jpg";
import axios from "axios";
import { serverLink, shortCode } from "../../resources/constants";
import SideModal from "../../components/Common/SideModal";

const Board = (props) => {
    const { CanvasClass } = props
    const [boards, setBoard] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getBoardMembers() {
            const result = await axios.get(`${serverLink}leadership/board/list`);
            setBoard(result.data);
            setLoading(false);
        }
        getBoardMembers();
    });

    const canvasMenuAdd = (board) => {
        setDet({
            ...det_,
            Image: `${serverLink}public/uploads/${shortCode}/leaderships_uploads/${board.ImagePath}`,
            Description: <p dangerouslySetInnerHTML={{ __html: board.Description }} />

        })
        document.body.classList.add('nav-expanded');
    };

    const [det_, setDet] = useState({
        Image: "",
        Description: ""
    })

    return (
        <React.Fragment>
            <OffWrap />

            <SiteBreadcrumb pageTitle="Board of Trustees"
                pageName="Board of Trustees"
                breadcrumbsImg={bannerbg} />

            <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">
                <div className="container">
                    <div className="col-12">
                        <h3 className="text-center" style={{color: "#273c66"}}><strong>Board of Trustees</strong></h3>
                        <div className="d-flex align-items-center align-content-between flex-column">
                            <div className="row">
                                {
                                    boards.map((board, index) =>
                                    (
                                        <div
                                            className={`text-center ${index === 0 ? "col-md-12 d-block" : "col-md-4"}  `}
                                            key={index}
                                        >
                                            <img
                                                src={`${serverLink}public/uploads/${shortCode}/leaderships_uploads/${board.ImagePath}`}
                                                alt={board.FullName}
                                                className="img-thumbnail"
                                                style={{
                                                    height: 350,
                                                    width: 400,
                                                }}
                                            />
                                            <h4 className="my-2" style={{color: "#273c66"}}>
                                                <strong>{board.Title} {board.FullName}</strong>
                                            </h4>
                                            <h6 className="text-center mb-2" style={{color: "#273c66"}}><strong>{board.Designation}</strong></h6>
                                            <p style={{ cursor: 'pointer' }} className="text-center mb-5"
                                                onClick={() => { canvasMenuAdd(board) }}
                                            >View profile</p>
                                        </div>

                                    )
                                    )
                                }
                            </div>
                            <SideModal
                                canvasClass={CanvasClass ? CanvasClass : "right_menu_togle orange_color hidden-md"}
                                canvasLogo={det_?.Image}
                            >
                                <div style={{textAlign : "justify"}}>
                                    {det_?.Description}
                                </div>
                            </SideModal>
                        </div>
                    </div>
                </div>
            </div>


            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

        </React.Fragment>
    )
}

export default Board;
import React from 'react'
import SiteBreadcrumb from '../../components/Common/Breadcumb'
import bannerbg from '../../assets/images/banner.jpg';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header';
import Logo from '../../assets/img/logo/dark-logo.png';
import AboutMain from '../about-2/AboutMain';


const AboutUs = () => {
    return (
        <React.Fragment >
            <OffWrap />

            <SiteBreadcrumb pageTitle="About"
                pageName="About Us"
                breadcrumbsImg={bannerbg} />
            <AboutMain />
        </React.Fragment>
    )
}

export default AboutUs;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {currencyConverter} from "../../../resources/constants";
import {setCourseDataDetails, setCourseListDetails} from "../../../actions/setactiondetails";
import {connect} from "react-redux";

const CourseSidebar = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    console.log(props.courseList)

    return (
        <div className="inner-column">
            <div className="course-features-info" style={{marginTop: '0px'}}>
                <ul>
                    <li className="lectures-feature">
                        <i className="fa fa-clock-o"/>
                        <span className="label">Duration:</span>
                        <span className="value">{`${props.courseList[0]?.Duration} ${props.courseList[0]?.DurationType}`}</span>
                    </li>
                    
                    <li className="quizzes-feature">
                        <i className="fa fa-certificate"/>
                        <span className="label">Degree In View:</span>
                        <span className="value">{props.courseList[0]?.DegreeInView}</span>
                    </li>
                    
                    <li className="duration-feature">
                        <i className="fa fa-building"/>
                        <span className="label">Department: </span>
                        <span className="value">{props.courseList[0]?.DepartmentName} </span>
                    </li>
                    
                    <li className="students-feature">
                        <i className=""><b>₦</b></i>
                        <span className="label">Tuition (Local):</span>
                        <span className="value"> {currencyConverter(props.courseList[0]?.TuitionFee)}</span>
                    </li>

                    <li className="students-feature">
                        <i className="fa fa-users"></i>
                        <span className="label">Tuition (Int'l):</span>
                        <span className="value"> $ 3,000</span>
                    </li>

                </ul>
                <a href={"https://admission.luxmundi.smartsourcing.ng/"} className="btn readon2 orange w-100 mt-3 mb-4">
                    Apply Now
                </a>
            </div>                
            <div className="btn-part">
                <h3>Related Courses</h3>
                <ul className="list-group">
                    {props.courseListAll.filter(
                        (item) => item.FacultyCode === props.courseList[0]?.FacultyCode
                    ).length > 0 &&
                    props.courseListAll
                        .filter(
                            (item) => item.FacultyCode === props.courseList[0]?.FacultyCode
                        )
                        .map((course, index) => {
                            if (course.CourseCode !== props.courseList[0]?.CourseCode) {
                                return (
                                    <li  className="mb-2" key={index}>
                                        <Link className="btn readon2 orange-transparent btn-sm " to={`/course/${course.Slug}`}>

                                            {course.CourseName}
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                </ul>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        courseListAll: state.CourseListDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnCourseListDetail: (p) => {
            dispatch(setCourseListDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseSidebar);

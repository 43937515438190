import React from 'react';
import Footer from '../../components/Layout/Footer/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Newsletter from '../../components/Common/Newsletter';
import ScrollToTop from '../../components/Common/ScrollTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

// Images
import bannerbg from '../../assets/images/finance2.jpg';
// Fees Images
import fee from '../../assets/images/fee2.jpg';
import hostel from '../../assets/images/hostel.jpg';
import cafeteria from '../../assets/images/cafteria.jpg';


import {Link} from "react-router-dom";

const Fees = () => {
    return (
        <React.Fragment>

            {/* breadcrumb-area-start */}
            <SiteBreadcrumb
                pageTitle="Fees"
                pageName="Fees"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            <div className="rs-event modify1 orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className={'event-item'}>
                                <div className="event-item">
                                    <div className="event-short">
                                        <div className="featured-img">
                                            <img src={fee} alt="Image" className="img-fluid" style={{minHeight: '250px', maxHeight: '250px'}} />
                                            <div className="dates">
                                                {'Fees'}
                                            </div>
                                        </div>
                                        <div className="content-part">
                                            <h4 className="title" style={{fontSize: '20px'}}><Link to="/fees/tuition">{'Find out everything you need to know to plan your student finances'}</Link></h4>
                                            <p className="text"> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className={'event-item'} >
                                <div className="event-item">
                                    <div className="event-short">
                                        <div className="featured-img">
                                            <img src={hostel} alt="Image" style={{minHeight: '250px', maxHeight: '250px'}} />
                                            <div className="dates">
                                                {'Accommodation'}
                                            </div>
                                        </div>
                                        <div className="content-part">
                                            <h4 className="title" style={{fontSize: '20px'}}><Link to="/fees/accommodation">{'Accommodation right in the university campus'}</Link></h4>
                                            <p className="text"/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className={'event-item'}>
                                <div className="event-item">
                                    <div className="event-short">
                                        <div className="featured-img">
                                            <img src={cafeteria} alt="Image" style={{minHeight: '250px', maxHeight: '250px'}} />
                                            <div className="dates">
                                                {'Feeding'}
                                            </div>
                                        </div>
                                        <div className="content-part">
                                            <h4 className="title" style={{fontSize: '19px'}}><Link to="/fees/feeding">{'The University has different cafeteria\'s with different types of meals'}</Link></h4>
                                            <p className="text"> </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}

            <SearchModal />
        </React.Fragment>
    );
}

export default Fees;
import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ContactInfo from '../../components/Contact/ContactInfo';

import bannerbg from '../../assets/images/banner.jpg';
import { googleMapAdress, projectAddress, projectEmail, projectPhone } from '../../resources/constants';


const ContactMain = () => {

    return (
        <React.Fragment>

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="Contact"
                pageName="Contact"
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact style1 event-bg pt-110 md-pt-80 pb-100 md-pb-80">
                <div className="container pb-66 md-pb-46">
                    <div className="row gutter-35">
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Address"
                                iconClass="flaticon-location"
                                address={projectAddress}
                            />
                        </div>
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass="sm-mb-30"
                                title="Email Address"
                                iconClass="flaticon-email"
                                email={projectEmail}
                            />
                        </div>
                        <div className="col-md-4">
                            <ContactInfo
                                boxClass=""
                                title="Phone Number"
                                iconClass="flaticon-phone"
                                phone={projectPhone}
                            />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-12 md-mb-30">
                            <div className="map-canvas">
                                <iframe src={googleMapAdress}></iframe>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* Contact Section End */}
        </React.Fragment>

    );
}


export default ContactMain;
import React, { useState } from 'react';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';

import bannerbg from '../../assets/images/banner.jpg';
import FacultyMain from './AboutMain';
import { serverLink, shortCode } from '../../resources/constants';

const Faculty = () => {
    const [mainPagefaculty, setMainPageFaculty] = useState([]);
    return (
        <React.Fragment>

            <SiteBreadcrumb
                pageTitle={mainPagefaculty[0]?.FacultyName}
                pageName={mainPagefaculty[0]?.FacultyName}
                breadcrumbsImg={mainPagefaculty.length > 0 ?
                    mainPagefaculty[0]?.FacultyImage !== "" ?
                        `${serverLink}public/uploads/${shortCode}/faculty_uploads/${mainPagefaculty[0]?.FacultyImage}`
                        : bannerbg
                    : bannerbg}
            />
            {/* breadcrumb-area-start */}

            {/* About Main */}
            <FacultyMain setMainPageFaculty={setMainPageFaculty} />
            {/* About Main */}

            <SearchModal />
        </React.Fragment>
    );
}

export default Faculty;
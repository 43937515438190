import React, { useState } from 'react';
import SingleEventThree from '../../components/Events/SingleEventThree';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import bannerbg from '../../assets/images/banner.jpg';
import eventImg1 from '../../assets/img/event/style3/1.jpg';
import { connect } from 'react-redux';
import { Alert } from '@mui/material';
import { convertTime, formatDateAndTime, serverLink, shortCode } from '../../resources/constants';

const EventThree = (props) => {

    const [eventsList, setEventsList] = useState(props.EventList.length > 0 ? props.EventList : []);


    return (
        <React.Fragment>
            <SiteBreadcrumb
                pageTitle="Events"
                pageName="Event"
                breadcrumbsImg={bannerbg}
            />
            {/* breadcrumb-area-End */}

            <div className="rs-event modify2 orange-style pt-100 pb-100 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row">
                        {
                            eventsList.length > 0 ?
                                eventsList.map((item, index) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 mb-30">
                                            <SingleEventThree
                                                eventClass='event-item'
                                                eventImg={`${serverLink}public/uploads/${shortCode}/event_uploads/${item.ImagePath}`}
                                                eventLocation={item.Location}
                                                eventDate={formatDateAndTime(item.EventDate, "date")}
                                                eventSchedule={convertTime(item.StartTime) + " -- " + convertTime(item.EndTime)}
                                                eventTitle={item.Title}
                                                slug={item.Slug}
                                            />
                                        </div>
                                    )
                                })
                                :
                                <Alert severity='danger'  >No Events Data</Alert>
                        }
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        EventList: state.EventDetails,
    };
};

export default connect(mapStateToProps, null)(EventThree);

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const RSMobileMenu = ({ setMenuOpen, menuOpen, parentMenu, secondParentMenu, headerFullWidth }) => {

	const location = useLocation();

	const [home, setHome] = useState(false)
	const [about, setAbout] = useState(false)
	const [course, setCourse] = useState(false)
	const [pages, setPages] = useState(false)
	const [team, setTeam] = useState(false)
	const [event, setEvent] = useState(false)
	const [gallery, setGallery] = useState(false)
	const [shop, setShop] = useState(false)
	const [otherPages, setOtherPages] = useState(false)
	const [blog, setBlog] = useState(false)
	const [blogSidebar, setBlogSidebar] = useState(false)
	const [blogSingle, setBlogSingle] = useState(false)
	const [contact, setContact] = useState(false);
	const [academics, setAcademics] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setAbout(!about)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'course') {
			setHome(false)
			setAbout(false)
			setCourse(!course)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'pages') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(!pages)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'team') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(true)
			setTeam(!team)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'event') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(true)
			setTeam(false)
			setEvent(!event)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'gallery') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(true)
			setTeam(false)
			setEvent(false)
			setGallery(!gallery)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'shop') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(true)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(!shop)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'otherPages') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(true)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(!otherPages)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(!blog)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'blogSidebar') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(true)
			setBlogSidebar(!blogSidebar)
			setBlogSingle(false)
			setContact(false)
		}
		else if (menu === 'blogSingle') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(true)
			setBlogSidebar(false)
			setBlogSingle(!blogSingle)
			setContact(false)
		}
		else if (menu === 'contact') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(!contact)
		}else if (menu === 'academics') {
			setHome(false)
			setAbout(false)
			setCourse(false)
			setPages(false)
			setTeam(false)
			setEvent(false)
			setGallery(false)
			setShop(false)
			setOtherPages(false)
			setBlog(false)
			setBlogSidebar(false)
			setBlogSingle(false)
			setContact(false)
			setAcademics(!academics)
		}
	};

	return (
		<div className={headerFullWidth ? "container-fluid relative" : "container relative"}>
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li className={"menu-item"}>
							<Link to="#" onClick={() => { openMobileMenu('home'); }} className={parentMenu === 'home' ? 'active-menu' : ''}>Home</Link>
						</li>
						<li className={about ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('about'); }} className={parentMenu === 'about' ? 'active-menu' : ''}>About</Link>
							<ul className={about ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="/about-us" className={location.pathname === "/about" ? "active-menu" : ""}>About Us</Link>
								</li>
								<li>
									<Link to="/our-campus" className={location.pathname === "/about-2" ? "active-menu" : ""}>Our Campus</Link>
								</li>
								<li>
									<Link to="/board-of-trustee" className={location.pathname === "/about-2" ? "active-menu" : ""}>Board of Trustee</Link>
								</li>
								<li>
									<Link to="/management" className={location.pathname === "/about-2" ? "active-menu" : ""}>Management</Link>
								</li>
								<li>
									<Link to="/senate" className={location.pathname === "/about-2" ? "active-menu" : ""}>Senate</Link>
								</li>
							</ul>
						</li>
						<li className={course ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('course'); }} className={parentMenu === 'course' ? 'active-menu' : ''}>Admission</Link>
							<ul className={course ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="/course-finder" className={location.pathname === "/course" ? "active-menu" : ""}>Course Finder</Link>
								</li>
								<li>
									<Link to="/fees" className={location.pathname === "/course-2" ? "active-menu" : ""}>Fees</Link>
								</li>
								<li>
									<Link to="/apply-now" className={location.pathname === "/course-3" ? "active-menu" : ""}>Apply Now</Link>
								</li>
							</ul>
						</li>

						<li className={academics ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('academics'); }} className={parentMenu === 'academics' ? 'active-menu' : ''}>Academics</Link>
							<ul className={academics ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="/our-staff" className={location.pathname === "/blog" ? "active-menu" : ""}>Staff</Link>
								</li>
								<li>
									<Link to="/learning-goals" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Learning Goals</Link>
								</li>
								<li>
									<Link to="/academic-registry" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Academic Registry</Link>
								</li>
								<li>
									<Link to="/academic-calender" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Academic Calender</Link>
								</li>
							</ul>
						</li>


						<li className={blog ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('blog'); }} className={parentMenu === 'blog' ? 'active-menu' : ''}>Resources</Link>
							<ul className={blog ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="/news" className={location.pathname === "/blog" ? "active-menu" : ""}>News</Link>
								</li>
								<li>
									<Link to="/events" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Events</Link>
								</li>
								<li>
									<Link to="/gallery" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Gallery</Link>
								</li>
								<li>
									<Link to="/careers" className={secondParentMenu === 'blogSidebar' ? 'menu-item-has-children active-menu' : 'menu-item-has-children'}>Careers</Link>
								</li>
							</ul>
						</li>
						<li className={"menu-item"}>
							<Link to="#" onClick={() => { openMobileMenu('contact'); }} className={parentMenu === 'contact' ? 'active-menu' : ''}>Contact</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;
import React from 'react';
import RecentPost from '../../components/Widget/RecentPost';
import SearchWidget from '../../components/Widget/SearchWidget';
import CategoriesWidget from '../../components/Widget/CategoriesWidget';

const SinglePostSidebar = (props) => {
    
    return (
        <>
            <SearchWidget onSearch={props.onSearch} />
            {
                props.isDetails &&
                <RecentPost pageData={props.pageData} type={props.type} />
            }
            <CategoriesWidget categories={props.categories} onCategoryChange={props.onCategoryChange} />
            {/* <MetaWidget /> */}
        </>
    );
}

export default SinglePostSidebar;